import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AgenxAuthComponent } from '../../ds/templates/auth/auth.component';

import { AccountService } from '../../../services/account.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [AgenxAuthComponent, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  private subscription: Subscription = new Subscription;

  error: string = '';
  userAuth: FormControl = new FormControl('', Validators.required);
  passwordAuth: FormControl = new FormControl('', Validators.required);
  state_button: "loading" | "normal" = "normal";

  /**
 * Handles the authentication process.
 * @param {any} $event - The authentication event.
 */
  authentication($event: any) {
    this.state_button = 'loading';
    this.subscription.add(
      this.accountSrv.login($event)
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard');
            this.state_button = 'normal'
          },
          error: (err) => {
            this.state_button = 'normal'
            this.error = err.error.detailed;
            console.log('err =>', err);
          }
        })
    );
  }

  constructor(
    private accountSrv: AccountService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
