import { Injectable } from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts: Array<any> = [];

  private ToastLoad = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast: any) => {
      Swal.showLoading()
    }
  });

  constructor() { }

  error(message: string, time: number = 8): void {
    time = time * 1000;
    let Toast = Swal.mixin({
      toast: true, position: 'top-end',
      showConfirmButton: false,
      timer: time, timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    let toast = Toast.fire({icon: 'error', title: message});
    this.toasts.push(toast);
  }

  info(message: string, time: number = 6): void {
    time = time * 1000;
    let Toast = Swal.mixin({
      toast: true, position: 'top-end',
      showConfirmButton: false,
      timer: time, timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    let toast = Toast.fire({icon: 'info', title: message});
    this.toasts.push(toast);
  }

  load(message: string) {
    return this.ToastLoad.fire({icon: 'info', title: message});
  }

  success(message: string, time: number = 8): void {
    time = time * 1000;
    let Toast = Swal.mixin({
      toast: true, position: 'top-end',
      showConfirmButton: false,
      timer: time, timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    let toast = Toast.fire({icon: 'success', title: message});
    this.toasts.push(toast);
  }

  warning(message: string, time: number = 6): void {
    time = time * 1000;
    let Toast = Swal.mixin({
      toast: true, position: 'top-end',
      showConfirmButton: false,
      timer: time, timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    let toast = Toast.fire({icon: 'warning', title: message});
    this.toasts.push(toast);
  }

  updateText(text: string): void {
    Swal.update({title: text});
  }
}
