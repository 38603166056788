import { Component, HostListener } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { AgenxCardComponent } from '../../ds/atoms/card/card.component';
import { AgenxInputComponent } from '../../ds/atoms/input/input.component';
import { AgenxHeaderComponent } from '../../ds/moleculs/header/header.component';
import { AgenxCardCreateComponent } from '../../ds/moleculs/card-create/card-create.component';
import { AgenxSelectComponent } from '../../ds/atoms/select/select.component';
import { AgenxTextareaComponent } from '../../ds/atoms/textarea/textarea.component';
import { AgenxTableComponent } from '../../ds/atoms/table/table.component';
import { AgenxButtonComponent } from '../../ds/atoms/button/button.component';
import { AgenxAlertComponent } from '../../ds/atoms/alert/alert.component';
import { AgenxAlertRadioComponent } from '../../ds/moleculs/alert-radio/alert-radio.component';
import { AgenxRadioComponent } from '../../ds/atoms/radio/radio.component';
import { AgenxCardSubComponent } from '../../ds/organism/card-sub/card-sub.component';
import { AgenxInputImgComponent } from '../../ds/atoms/input-img/input-img.component';
import { AgenxPaginatorComponent } from '../../ds/atoms/paginator/paginator.component';
import { AgenxSignatureComponent } from '../../ds/organism/signature/signature.component';

import { AccountService } from '../../../services/account.service';
import { DashboardService } from '../../../services/dashboard.service';
import { GaugingService } from '../../../services/gauging.service';
import { SubscriberService } from '../../../services/subscriber.service';

import { Container} from '../../../interfaces/create-edit.interface';
import { GaugingContent, CardContent, GaugingFull } from '../../../interfaces/gauging.interface';
import { Router } from '@angular/router';
import { Signs, SubsData, Subscriber} from '../../../interfaces/person.interface';
import { Subscription, first } from 'rxjs';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-create-edit',
  standalone: true,
  imports: [
    MatDialogModule,
    AgenxSignatureComponent,
    AgenxPaginatorComponent,
    AgenxInputImgComponent,
    AgenxCardSubComponent,
    AgenxCardComponent,
    AgenxRadioComponent,
    AgenxAlertRadioComponent,
    AgenxAlertComponent,
    AgenxButtonComponent,
    AgenxTableComponent,
    AgenxTextareaComponent,
    AgenxSelectComponent,
    AgenxInputComponent,
    AgenxHeaderComponent,
    AgenxCardCreateComponent,
    ReactiveFormsModule,
    SweetAlert2Module,
  ],
  templateUrl: './create-edit.component.html',
  styleUrl: './create-edit.component.scss',
})
export class CreateEditComponent {
  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }) {
    this.card_x_page = event.target.innerWidth > 1580 ? 8 : 6;
  }
  private subscriptions: Subscription[] = [];

  card_info!: CardContent;
  name: FormControl = new FormControl({
    value: '',
    disabled: true,
  });
  identification: FormControl = new FormControl({
    value: '1004081190',
    disabled: true,
  });
  niud: FormControl = new FormControl({ value: '0811', disabled: true });
  stratum: FormControl = new FormControl({ value: '3', disabled: true });
  address: FormControl = new FormControl({
    value: 'Privet Drive N°4, Little Whinging',
    disabled: true,
  });
  email: FormControl = new FormControl({
    value: '',
    disabled: true,
  });
  newEmail: FormControl = new FormControl({ value: '', disabled: false });
  photos: FormControl = new FormControl({ value: '', disabled: false });
  formAforos: Array<{ name: string; control: FormControl }> = [
    {
      name: 'radioWaste',
      control: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    },
    {
      name: 'radioAforo',
      control: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    },
    {
      name: 'typeContainer',
      control: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    },
    {
      name: 'sizeContainer',
      control: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    },
    {
      name: 'quantity',
      control: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern('^\\d+(\\.\\d)?$'),
        Validators.min(0.1),
      ]),
    },
    {
      name: 'observations',
      control: new FormControl({ value: '', disabled: false }, []),
    },
  ];
  gauging_data!: GaugingFull | null;
  aforosContent: Array<GaugingContent> = [];
  types_conta: Array<{ value: string; label: string }> = [];
  sizes_conta: Array<{ value: string; label: string }> = [];
  containers: Array<Container> = [];
  equivalency_table = [
    { td: ['Cantidad', 'M³', 'Kg'] },
    { td: ['0', '0', '0'] },
  ];
  cards_content: CardContent[] = [];
  data_form!: SubsData;
  files: Array<any> = [];
  btn_add = 'Agregar';
  selectedWaste = '';
  cards_page: CardContent[] = [];
  pages: number[] = [];
  pageSelected: number = 1;
  newPage: boolean = true;
  card_x_page = 8;
  card_selected!: number;
  load_evidences: Array<{ name: string; file: string }> = [];
  signatureImg: string | null = '';
  error_sign_flume = false;
  signatureImgSub: string | null = '';
  error_sign_sub = false;
  save_btn: 'loading' | 'normal' = 'normal';
  loading = false;
  editing = false;
  in_kgrs = false;

  constructor(
    public router: Router,
    private dashboardSrv: DashboardService,
    private gaugingSrv: GaugingService,
    private accountSrv: AccountService,
    private toastSrv: ToastService,
    public dialog: MatDialog
  ) {}

  /**
   * Permanently deletes a file and provides user feedback.
   *
   * @param {any} file_del - The file object to be deleted.
   * @returns {void}
   */
  deletePermanentFile(file_del: any): void {
    this.subscriptions.push(
      this.gaugingSrv
        .deleteFile(file_del.id)
        .pipe(first())
        .subscribe({
          next: () => {
            this.toastSrv.success('La imagen fue eliminada correctamente.');
          },
          error: (error: { error: { detailed: string } }) => {
            console.log(error);
            let message = error.error.hasOwnProperty('detailed')
              ? error.error.detailed
              : 'No se puede eliminar el archivo.';
            this.toastSrv.error(message);
          },
        })
    );
  }

  /**
   * Deletes the signature image based on the specified type.
   *
   * @param {string} type - The type of signature to delete ('sub' or other).
   * @returns {void}
   */
  deleteSignature(type: string): void {
    if (type === 'sub') {
      this.signatureImgSub = '';
    } else {
      this.signatureImg = '';
    }
  }

  /**
   * Opens the dialog to edit the signature based on the specified type.
   *
   * @param {string} type - The type of signature to edit ('sub' or other).
   * @returns {void}
   */
  editSignature(type: string): void {
    if (type === 'sub') {
      this.openDialogSignature('sub');
    } else {
      this.openDialogSignature('');
    }
  }

  /**
   * Gets the FormControl object for the specified form field.
   *
   * @param {string} field - The name of the form field.
   * @returns {FormControl} - The FormControl object associated with the field.
   */
  getControlOf(field: string): FormControl {
    let i = this.formAforos.findIndex((ele) => ele.name === field);
    return this.formAforos[i].control;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subsciption) => subsciption.unsubscribe());
    this.dashboardSrv.cleanSubsInfo();
  }

  ngOnInit(): void {
    this.loading = true;
    this.card_info = this.dashboardSrv.loadSubsInfo();
    !this.card_info && this.router.navigateByUrl('/dashboard');
    this.data_form = {
      name: this.card_info.valuesContent[1].value ?? '',
      niud: this.card_info.valuesContent[0].value,
      stratum: this.card_info.stratum_id,
      address: this.card_info.valuesContent[2].value,
      email: this.card_info.email ?? '',
      identification: this.card_info.identification ?? '',
    };
    this.newEmail.addValidators(this.emailListValidator);
    this.updateSubsForm(this.data_form);

    this.subscriptions.push(
      this.gaugingSrv.getContainers().subscribe({
        next: (resp: Container[]) => {
          this.containers = resp;
          resp.forEach((cont) => {
            !this.types_conta.find((type) => type.label === cont.type_name) &&
              this.types_conta.push({
                value: cont.type_name,
                label: cont.type_name,
              });
          });
          setTimeout(() => this.onCancel());
          // this.gauging_data =
          //   this.dashboardSrv
          //     .loadGaugingSub()
          //     .find((gau) => gau.subscriber.niud === this.data_form.niud) ??
          //   null;
          // this.gauging_data && setTimeout(() => this.onEditGaugingMode());
          this.loading = false;
        },
        error: (error: { error: { detailed: string } }) => {
          let messageError = error.error.hasOwnProperty('detailed')
            ? error.error.detailed
            : 'No se pudo obtener la información de los recipientes.';
          this.toastSrv.error(messageError);
        },
      })
    );

    this.subscriptions.push(
      this.gaugingSrv
        .getSigns(this.data_form.niud, this.accountSrv.userValue?.username!)
        .subscribe((resp: Signs) => {
          this.signatureImg = resp.path_sing_flume ?? '';
        })
    );
  }

  /**
   * Adds a new 'Aforo' (measurement) if the form is valid.
   * Resets the form and updates the paginator.
   *
   * @returns {void}
   */
  onAddAforo(): void {
    if (!this.formAforos.find((element) => element.control.invalid)) {
      let type = this.getControlOf('typeContainer').value;
      let size = this.getControlOf('sizeContainer').value;
      let page = 0;
      let container = null;
      let m3 = 0;
      let kgrs = 0;
      let last_index = this.cards_content.length;
      let valuesContent = [{ title: 'Tipo de recipiente:', value: type }];

      if (this.in_kgrs) {
        container = this.containers.find((cont) => cont.type_name === 'Kilos')!;
        kgrs = parseFloat(this.getControlOf('quantity').value);
        valuesContent.push({
          title: 'Kg. totales:',
          value: kgrs,
        });
      } else {
        container = this.containers.find(
          (conta) => conta.type_name === type && conta.capacity === size
        )!;
        m3 = parseFloat(this.equivalency_table[2].td[1]);
        valuesContent.push({ title: 'Tamaño:', value: container.name });
        valuesContent.push({
          title: 'M³ volumen:',
          value: m3,
        });
      }

      let data_gaguging: GaugingContent = {
        container_id: container!.id,
        quantity: parseFloat(this.getControlOf('quantity').value),
        m3,
        kgrs,
      };
      if (this.getControlOf('observations').value) {
        data_gaguging['observations'] =
          this.getControlOf('observations').value;
        valuesContent.push({
          title: 'Observaciones:',
          value: this.getControlOf('observations').value,
        });
      }

      this.aforosContent.push(data_gaguging);
      // this.getControlOf('observations').value &&
      //   valuesContent.push({
      //     title: 'Observaciones:',
      //     value: this.getControlOf('observations').value,
      //   });
      this.cards_content.push({
        valuesContent,
        quantity: this.getControlOf('quantity').value,
      });
      page = Math.trunc(last_index / this.card_x_page) + 1;
      if (this.pages.indexOf(page) === -1) {
        this.newPage = false;
        this.pages.push(page);
        this.newPage = true;
      }
      this.cards_content[last_index].page = page;
      this.setPaginator(page);
      this.btn_add = 'Agregar';
      this.onCancel();
    } else {
      this.formAforos.forEach((element) => element.control.markAsTouched());
    }
  }

  /**
   * Resets the form fields and updates the equivalency table.
   * Handles UI updates for the waste and gauging elements.
   *
   * @returns {void}
   */
  onCancel(): void {
    this.getControlOf('typeContainer').reset();
    this.getControlOf('sizeContainer').reset();
    this.getControlOf('quantity').reset();
    this.getControlOf('observations').reset();
    this.equivalency_table = [
      { td: ['Cantidad', 'M³', 'Kg'] },
      { td: ['0', '0', '0'] },
    ];
    const elem_waste = document.getElementById(`waste-2`);
    const code =
      this.card_info.gaugin_code !== 1 ? this.card_info.gaugin_code! - 1 : 2;
    const elem_gauging = document.getElementById(`aforo-${code}`);
    elem_gauging?.click();
    this.getControlOf('radioAforo').disable();
    elem_waste?.click();
    this.editing = false;
  }

  /**
   * Handles the selection of a sub-card. If the card is to be edited, populates the form fields.
   * If the card is to be deleted, calls the deleteAforo method.
   *
   * @param {any} card - The card object that was clicked.
   * @returns {void}
   */
  onClickSubCard(card: any): void {
    this.editing = true;
    this.card_selected = card.index;
    this.cards_content.map((sub: CardContent) => {
      sub.color = 'blue';
    });
    this.cards_page[card.index].color = 'orange';

    if (card.cardSubButtonType === 'Edit') {
      this.btn_add = 'Guardar';
      let type_conta = this.types_conta.find(
        (type) =>
          type.label === this.cards_page[card.index].valuesContent[0].value
      );
      this.getControlOf('typeContainer').setValue(type_conta?.value);
      this.setSizesValues(undefined, type_conta?.value);
      let size = this.containers.find(
        (cont) =>
          cont.type_name === type_conta?.value &&
          cont.name === this.cards_page[card.index].valuesContent[1].value
      );
      this.getControlOf('sizeContainer').setValue(size?.capacity);
      this.getControlOf('quantity').setValue(card.quantity);
      this.setEquivalencyTable();
      if (type_conta?.value === 'Kilos') {
        this.getControlOf('observations').setValue(
          this.cards_page[card.index].valuesContent[2]?.value ?? ''
        );
      } else {
        this.getControlOf('observations').setValue(
          this.cards_page[card.index].valuesContent[3]?.value ?? ''
        );
      }
    } else {
      this.deleteAforo(card.index);
    }
  }

  /**
   * Edits an existing 'Aforo' (measurement) if the form is valid.
   * Updates the aforosContent and cards_content arrays, and resets the form.
   *
   * @returns {void}
   */
  onEditCard(): void {
    if (!this.formAforos.find((element) => element.control.invalid)) {
      let type = this.getControlOf('typeContainer').value;
      let size = this.getControlOf('sizeContainer').value;
      let container = null;
      let m3 = 0;
      let kgrs = 0;
      let valuesContent = [{ title: 'Tipo de recipiente:', value: type }];
      let global_index =
        (this.pageSelected - 1) * this.card_x_page + this.card_selected;

      if (this.in_kgrs) {
        container = this.containers.find((cont) => cont.type_name === 'Kilos')!;
        kgrs = parseFloat(this.getControlOf('quantity').value);
        valuesContent.push({
          title: 'Kg. totales:',
          value: kgrs,
        });
      } else {
        container = this.containers.find(
          (conta) => conta.type_name === type && conta.capacity === size
        )!;
        m3 = parseFloat(this.equivalency_table[2].td[1]);
        valuesContent.push({ title: 'Tamaño:', value: container.name });
        valuesContent.push({
          title: 'M³ volumen:',
          value: m3,
        });
      }

      this.aforosContent[global_index] = {
        container_id: container.id,
        quantity: parseFloat(this.getControlOf('quantity').value),
        m3,
        kgrs,
      };
      if (this.getControlOf('observations').value) {
        this.aforosContent[global_index]['observations'] =
          this.getControlOf('observations').value;
        valuesContent.push({
          title: 'Observaciones:',
          value: this.getControlOf('observations').value,
        });
      }
      this.cards_content[global_index] = {
        valuesContent,
        quantity: this.getControlOf('quantity').value,
        page: this.pageSelected,
      };
      this.setPaginator(this.pageSelected);
      this.onCancel();
      this.btn_add = 'Agregar';
    } else {
      this.formAforos.forEach((element) => element.control.markAsTouched());
    }
  }

  /**
   * Opens a dialog for capturing a signature and updates the signature image based on the type.
   *
   * @param {string} type - The type of signature ('sub' or other).
   * @returns {void}
   */
  openDialogSignature(type: string): void {
    const dialogRef = this.dialog.open(AgenxSignatureComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (type === 'sub') {
        result.signature && (this.signatureImgSub = result.signature);
      } else {
        result.signature && (this.signatureImg = result.signature);
      }
    });
  }

  /**
   * Deletes an 'Aforo' (measurement) after confirmation from the user.
   * Updates the aforosContent and cards_content arrays and the paginator.
   *
   * @param {number} index - The index of the 'Aforo' to be deleted.
   * @returns {void}
   * @private
   */
  private deleteAforo(index: number): void {
    let aux_page = 0;
    let index_page = 0;
    let text = `¿Está seguro de eliminar este aforo?`;

    Swal.fire({
      title: 'Eliminar aforo',
      html: text,
      icon: 'warning',
      confirmButtonColor: '#e64544',
      cancelButtonColor: '#8b8b8c',
      confirmButtonText: 'Eliminar',
      showCancelButton: true,
    }).then((result: any) => {
      Swal.hideLoading();
      if (result.isConfirmed) {
        index_page = (this.pageSelected - 1) * this.card_x_page + index;
        this.cards_content = this.cards_content.filter(
          (card, ind) => ind !== index_page
        );
        this.aforosContent = this.aforosContent.filter(
          (afo, ind) => ind !== index_page
        );
        this.pages = [];
        this.cards_content.map((card, ind) => {
          aux_page = Math.trunc(ind / this.card_x_page) + 1;
          if (this.pages.indexOf(aux_page) === -1) {
            this.newPage = false;
            this.pages.push(aux_page);
            this.newPage = true;
          }
          card.page = aux_page;
          return card;
        });
        this.pages.indexOf(this.pageSelected) === -1 &&
          (this.pageSelected -= 1);
        this.setPaginator(this.pageSelected);
        this.onCancel();
      }
    });
  }

  private emailListValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const email = control.value as string;

    if (!email) {
      return null; // No value, no errors
    }

    const trimmedEmail = email.replace(/\s+/g, '');
    const emailList = trimmedEmail.split(';');
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    for (const email of emailList) {
      if (!pattern.test(email)) {
        return { invalidEmailFormat: 'Correo con formato incorrecto.' };
      }
    }

    return null;
  };

  /**
   * Checks if the form is valid by ensuring 'aforosContent' is not empty and signatures are present.
   *
   * @private
   * @returns {boolean} True if the form is valid, otherwise false.
   */
  private isFormValid(): boolean {
    if (
      this.aforosContent.length === 0 ||
      !this.signatureImg ||
      !this.signatureImgSub
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Enters the edit mode for gauging, pre-filling the form with existing data.
   *
   * @private
   * @returns {void}
   */
  private onEditGaugingMode(): void {
    const elem_waste = document.getElementById(
      `waste-${this.gauging_data?.type_waste}`
    );
    const elem_aforo = document.getElementById(
      `aforo-${this.gauging_data?.type_gauging}`
    );
    elem_waste?.click();
    elem_aforo?.click();
    this.gauging_data?.gaugings.forEach((gau) => {
      this.getControlOf('typeContainer').setValue(gau.type_container);
      this.setSizesValues(undefined, gau.type_container);
      this.getControlOf('sizeContainer').setValue(gau.size_container);
      this.getControlOf('quantity').setValue(gau.quantity);
      this.setEquivalencyTable();
      this.getControlOf('observations').setValue(gau.observations);
      this.onAddAforo();
    });
    this.getControlOf('typeContainer').reset();
    this.getControlOf('sizeContainer').reset();
    this.getControlOf('quantity').reset();
    this.getControlOf('observations').reset();
    this.signatureImg = this.gauging_data?.sign_flume ?? '';
    this.signatureImgSub = this.gauging_data?.sign_subscriber ?? '';
    this.load_evidences = this.gauging_data?.evidences!;
  }

  /**
   * Updates the subscription form with the provided data.
   *
   * @private
   * @param {SubsData} data - The data to update the form with.
   * @returns {void}
   */
  private updateSubsForm(data: SubsData): void {
    this.name.setValue(data.name);
    this.niud.setValue(data.niud);
    this.stratum.setValue(data.stratum);
    this.address.setValue(data.address);
    this.email.setValue(data.email);
    this.identification.setValue(data.identification);
  }

  /**
   * Reads a file asynchronously and returns a promise that resolves with the file data.
   *
   * @private
   * @param {any} file - The file to be read.
   * @returns {Promise<unknown>} A promise that resolves with the file data.
   */
  private readFileAsync(file: any): Promise<unknown> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  /**
   * Saves the form data if the form is valid. Uploads evidence files and signatures.
   *
   * @async
   * @returns {Promise<void>}
   */
  async save(): Promise<void> {
    let evidence_files: Array<{ file: string; name: string }> = [];
    let id = this.gauging_data?.id;
    let img_flume = `${this.accountSrv.userValue?.username}.png`;
    let img_sub = `${this.niud.value}.png`;

    if (this.isFormValid() && this.newEmail.valid) {
      this.save_btn = 'loading';
      for (let file in this.files) {
        let file_data = await this.readFileAsync(this.files[file]);
        evidence_files.push({
          file: file_data as string,
          name: this.files[file].name,
        });
      }

      let data = {
        subscriber: this.card_info.id,
        flume: this.accountSrv.userValue?.id,
        evidence: evidence_files,
        sign_flume: { file: this.signatureImg, name: img_flume },
        sign_subscriber: { file: this.signatureImgSub, name: img_sub },
        type_waste: parseInt(this.getControlOf('radioWaste').value),
        type_gauging: parseInt(this.getControlOf('radioAforo').value),
        gaugings: this.aforosContent,
        new_email: this.newEmail.value,
      };
      !this.newEmail.value && delete data.new_email;

      this.subscriptions.push(
        this.gaugingSrv.formGauging(data, id).subscribe({
          next: (result) => {
            this.save_btn = 'normal';
            this.dashboardSrv.saveNotGaugingSub([]);
            this.dashboardSrv.saveGaugingSub([]);
            this.toastSrv.success('Aforo creado correctamente.');
            setTimeout(() => {
              result.error_email && this.toastSrv.error(result.error_email);
            }, 3000);
            this.router.navigateByUrl('/dashboard');
          },
          error: (error: { error: { detailed: string } }) => {
            this.save_btn = 'normal';
            let message = error.error.hasOwnProperty('detailed')
              ? error.error.detailed
              : 'No se pudo guardar el aforo.';
            this.toastSrv.error(message);
          },
        })
      );
    } else {
      this.error_sign_flume = true;
      this.error_sign_sub = true;
      this.newEmail.markAsTouched();
    }
  }

  /**
   * Sets the equivalency table based on the selected container size and quantity.
   *
   * @returns {void}
   */
  setEquivalencyTable(): void {
    let quantity = parseFloat(this.getControlOf('quantity').value);
    let equivalency = this.containers.find(
      (cont) => cont.capacity === this.getControlOf('sizeContainer').value
    );
    this.equivalency_table = [{ td: ['Cantidad', 'M³', 'Kg'] }];
    if (this.getControlOf('typeContainer').value === 'Kilos') {
      quantity &&
        this.equivalency_table.push({ td: ['0', '0', `${quantity}`] });
      return;
    } else {
      equivalency &&
        this.equivalency_table.push({
          td: ['1', equivalency.equivalency.toFixed(3), '0'],
        });
      quantity &&
        this.equivalency_table.push({
          td: [
            quantity.toString(),
            (quantity * equivalency!.equivalency).toFixed(3),
            '0',
          ],
        });
    }
  }

  /**
   * Updates the paginator and sets the current page content.
   *
   * @param {any} page - The page number to set the paginator to.
   * @returns {void}
   */
  setPaginator(page: any): void {
    this.cards_page = this.cards_content.filter((card) => card.page === page);
    this.pageSelected = page;
  }

  /**
   * Sets the sizes available for a container type.
   *
   * @param {HTMLSelectElement} [select] - The select element for container type.
   * @param {string} [value] - The container type value.
   * @returns {void}
   */
  setSizesValues(select?: HTMLSelectElement, value?: string): void {
    let type_value = select ? select.value : value;
    this.in_kgrs = false;
    this.sizes_conta = [];
    this.containers.forEach((cont) => {
      cont.type_name == type_value &&
        this.sizes_conta.push({
          value: cont.capacity ?? 'N/A',
          label: cont.name ?? 'N/A',
        });
    });
    if (type_value === 'Kilos') {
      this.getControlOf('sizeContainer').setValue('N/A');
      this.in_kgrs = true;
      this.setEquivalencyTable();
    }
  }
}

