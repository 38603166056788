<div class="agenx-auth__card">
  <picture class="agenx-auth__picture">
    <img class="agenx-auth__img" [src]="imgAuth.imgURL" [alt]="imgAuth.imgAlt" [title]="imgAuth.imgAlt">
  </picture>
  <form class="agenx-auth__form">
    <agenx-input [control]="user" [placeholder]="''" [idInput]="'Usuario'" [label]="'Usuario'"></agenx-input>
    <agenx-input [control]="password" [placeholder]="''" [idInput]="'Contraseña'" [label]="'Contraseña'"
      [type]="'password'" (onEnterPress)="returnAuthValues()"></agenx-input>
      <!-- <agenx-alert ></agenx-alert> -->
      @if (error) {
        <agenx-alert-radio [alertText]="error!" alertType="error"></agenx-alert-radio>
      }
  </form>
  <footer class="agenx-auth__footer">
    <agenx-button #buttonLogin (click)="returnAuthValues()" [buttonText]="'Iniciar sesión'"
      [sizeButton]="'large'" [typeButton]="'blue'"></agenx-button>
  </footer>
</div>
