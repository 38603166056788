import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'agenx-radio',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss'
})
export class AgenxRadioComponent {


  /**
   * Input label
   *
   * @type {string}
   * @memberof AgenxRadioComponent
   */
  @Input() label: string = 'Input label';

  /**
   * Input idInput
   *
   * @type {string}
   * @memberof AgenxRadioComponent
   */
  @Input() idInput: string = 'idInput';

  /**
   * Input nameInput
   *
   * @type {string}
   * @memberof AgenxRadioComponent
   */
  @Input() nameInput: string = 'nameInput';


  @Input() checked: boolean = false;

  @Input() value: string = 'value';

  /**
   * Current classes of the input
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxRadioComponent
   */
  labelClasses: Record<string, boolean> = {};

  /**
   * Input form control
   *
   * @type {FormControl}
   * @memberof AgenxRadioComponent
   */
  @Input() control: FormControl = new FormControl();


}
