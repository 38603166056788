<div class="agenx-totals">
  <div class="agenx-totals__top-part">
    <div class="agenx-totals__top-box">
      <article class="agenx-totals__top-total">
        <h4 class="agenx-totals__top-title">TOTAL AFORADO</h4>
        <h6 class="agenx-totals__top-subtitle">{{ totalGaugedSub }}</h6>
      </article>
      <h4 class="agenx-totals__top-value">{{ totalGauged }}</h4>
    </div>
    <div class="agenx-totals__top-box">
      <article class="agenx-totals__top-total">
        <h4 class="agenx-totals__top-title">TOTAL DEL MES</h4>
        <h6 class="agenx-totals__top-subtitle">{{ totalMonthSub }}</h6>
      </article>
      <h4 class="agenx-totals__top-value">{{ totalMonth }}</h4>
    </div>
    <div class="agenx-totals__top-box">
      <article class="agenx-totals__top-total">
        <h4 class="agenx-totals__top-title">TOTAL DEL DIA</h4>
        <h6 class="agenx-totals__top-subtitle">{{ totalDaySub }}</h6>
      </article>
      <h4 class="agenx-totals__top-value">{{ totalDay }}</h4>
    </div>
  </div>
  <div class="agenx-totals__middle-part">
    <p class="agenx-totals__middle-title">{{ todayDate }}</p>
    <span class="agenx-totals__middle-line"></span>
  </div>
  <div class="agenx-totals__bottom-part">
    <div class="agenx-totals__bottom-box">
      <h4 class="agenx-totals__bottom-title">{{ ordynary }}</h4>
      <h6 class="agenx-totals__bottom-subtitle">Ordinario</h6>
    </div>
    <div class="agenx-totals__bottom-box">
      <h4 class="agenx-totals__bottom-title">{{ extraOrdynary }}</h4>
      <h6 class="agenx-totals__bottom-subtitle">Extraordinario</h6>
    </div>
    <div class="agenx-totals__bottom-box">
      <h4 class="agenx-totals__bottom-title">{{ permanent }}</h4>
      <h6 class="agenx-totals__bottom-subtitle">Permanente</h6>
    </div>
  </div>
</div>
