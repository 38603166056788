import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgenxCircleButtonComponent } from "../circle-button/circle-button.component";

@Component({
    selector: 'agenx-card',
    standalone: true,
    templateUrl: './card.component.html',
    styleUrl: './card.component.scss',
    imports: [NgClass, AgenxCircleButtonComponent]
})
export class AgenxCardComponent implements OnInit {
  @Input() title: string = 'Card title';
  @Input() borderTopLess: boolean = false;
  @Input() borderBottomLess: boolean = false;
  @Input() button: string | null = null;
  @Output() buttonClick = new EventEmitter<boolean>();

  currentClasses: Record<string, boolean> = {};

  /**
   * Function to set the current classes of the card
   *
   * @memberof AgenxCardComponent
   */
  setCurrentClasses() {
    this.currentClasses = {
      'agenx-card--less-top': this.borderTopLess,
      'agenx-card--less-bottom': this.borderBottomLess,
    };
  }

  ngOnInit(): void {
    this.setCurrentClasses();
  }

  onButtonClick(): void {
    this.buttonClick.emit(true);
  }
}
