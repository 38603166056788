import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Subscriber } from '../interfaces/person.interface';
import { environment } from '../../environments/environment';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriberService {
  private url = environment.api_url;

  constructor(private accountSrv: AccountService, private http: HttpClient) {}

  getSubscribers(search: string): Observable<Subscriber[]> {
    let headers = this.accountSrv.headers;
    return this.http.get<Subscriber[]>(
      `${this.url}/subscribers/no_module/?search=${search}`,
      { headers }
    );
  }

  getSubscribersNotGauging(micro: string): Observable<Subscriber[]> {
    let headers = this.accountSrv.headers;
    let microroute = micro ? `?microroute=${micro}` : ''
    return this.http.get<Subscriber[]>(`${this.url}/subscribers/not_gauging/${microroute}`, {
      headers,
    });
  }
}
