import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitChars',
  standalone: true
})
export class LimitCharsPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    return value.length > limit ? `${value.slice(0, limit)}...` : value;
  }

}
