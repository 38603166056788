<agenx-header></agenx-header>
<main class="agenx-create-edit">
  @if (!loading) {
    <agenx-card-create [titleForCardInside]="'INFORMACIÓN SUSCRIPTOR'"
      [title]="'CREAR/EDITAR AFORO'">
      <div class="agenx-create-edit__inputs--three">
        <agenx-input [control]="name" [placeholder]="''" [labelPosition]="'top'" [idInput]="'Nombre'"
          [label]="'Nombre'"></agenx-input>
        <agenx-input [control]="identification" [placeholder]="''" [labelPosition]="'top'" [idInput]="'Identicación'"
          [label]="'Identicación'"></agenx-input>
        <agenx-input [control]="niud" [placeholder]="''" [labelPosition]="'top'" [idInput]="'Niud'"
          [label]="'Niud'"></agenx-input>
        <agenx-input [control]="stratum" [placeholder]="''" [labelPosition]="'top'" [idInput]="'Estrato'"
          [label]="'Estrato'"></agenx-input>
        <agenx-input [control]="address" [placeholder]="''" [labelPosition]="'top'" [idInput]="'Dirección'"
          [label]="'Direccion'"></agenx-input>
        <agenx-input [control]="email" [placeholder]="''" [labelPosition]="'top'" [idInput]="'Correo electrónico'"
          [label]="'Correo Electronico'"></agenx-input>
      </div>
      @if (!data_form.email) {
        <agenx-alert [alertType]="'error'" [alertText]="'Este usuario no cuenta con correo electronico definido.'"></agenx-alert>
      }
    </agenx-card-create>

    <agenx-card [title]="'CREAR AFORO'" [borderBottomLess]="true">
      <div class="agenx-create-edit__radios-containers">
        @if (getControlOf('radioWaste').touched && getControlOf('radioWaste').invalid) {
          <agenx-alert-radio [alertType]="'error'" [alertText]="'La recolección corresponde a residuos de tipo:'">
            <div class="agenx-create-edit__radios">
              <agenx-radio [control]="getControlOf('radioWaste')" [idInput]="'waste-1'" [value]="'1'" [checked]="true"
              [nameInput]="'recolección'" [label]="'Aprovechables. (Papel, cuadernos, libros, cartón, botellas, etc).'"
              class="agenx-alert-radio__radio"></agenx-radio>
              <agenx-radio [control]="getControlOf('radioWaste')" [idInput]="'waste-2'" [value]="'2'"
              [checked]="false" [nameInput]="'recolección'"
              [label]="'No aprovechables. (Envolturas, papel higiénico, bolsas de plástico, servilletas, etc)'"
              class="agenx-alert-radio__radio"></agenx-radio>
            </div>
          </agenx-alert-radio>
        } @else {
          <agenx-alert-radio [alertType]="'info'" [alertText]="'La recolección corresponde a residuos de tipo:'">
            <div class="agenx-create-edit__radios">
              <agenx-radio [control]="getControlOf('radioWaste')" [idInput]="'waste-1'" [value]="'1'" [checked]="true"
              [nameInput]="'recolección'" [label]="'Aprovechables. (Papel, cuadernos, libros, cartón, botellas, etc).'"
              class="agenx-alert-radio__radio"></agenx-radio>
              <agenx-radio [control]="getControlOf('radioWaste')" [idInput]="'waste-2'" [value]="'2'"
              [checked]="false" [nameInput]="'recolección'"
              [label]="'No aprovechables. (Envolturas, papel higiénico, bolsas de plástico, servilletas, etc)'"
              class="agenx-alert-radio__radio"></agenx-radio>
            </div>
          </agenx-alert-radio>
        }
        @if (getControlOf('radioAforo').touched && getControlOf('radioAforo').invalid) {
          <agenx-alert-radio [alertType]="'error'" [alertText]="'Se realiza un aforo de tipo:'">
            <div class="agenx-create-edit__radios">
              <agenx-radio [control]="getControlOf('radioAforo')" [idInput]="'aforo-1'" [value]="'1'" [checked]="true"
                [nameInput]="'aforo'" [label]="'Ordinario.'" class="agenx-alert-radio__radio"></agenx-radio>
              <agenx-radio [control]="getControlOf('radioAforo')" [idInput]="'aforo-2'" [value]="'2'" [checked]="false"
                [nameInput]="'aforo'" [label]="'Extraordinario.'" class="agenx-alert-radio__radio"></agenx-radio>
              <agenx-radio [control]="getControlOf('radioAforo')" [idInput]="'aforo-3'" [value]="'3'" [checked]="false"
                [nameInput]="'aforo'" [label]="'Permanente.'" class="agenx-alert-radio__radio"></agenx-radio>
            </div>
          </agenx-alert-radio>
        } @else {
          <agenx-alert-radio [alertType]="'info'" [alertText]="'Se realiza un aforo de tipo:'">
            <div class="agenx-create-edit__radios">
              <agenx-radio [control]="getControlOf('radioAforo')" [idInput]="'aforo-1'" [value]="'1'" [checked]="true"
                [nameInput]="'aforo'" [label]="'Ordinario.'" class="agenx-alert-radio__radio"></agenx-radio>
              <agenx-radio [control]="getControlOf('radioAforo')" [idInput]="'aforo-2'" [value]="'2'" [checked]="false"
                [nameInput]="'aforo'" [label]="'Extraordinario.'" class="agenx-alert-radio__radio"></agenx-radio>
              <agenx-radio [control]="getControlOf('radioAforo')" [idInput]="'aforo-3'" [value]="'3'" [checked]="false"
                [nameInput]="'aforo'" [label]="'Permanente.'" class="agenx-alert-radio__radio"></agenx-radio>
            </div>
          </agenx-alert-radio>
        }
      </div>

      <div class="agenx-create-edit__inputs--three">
        <agenx-select [idInput]="'typeRecolector'" [control]="getControlOf('typeContainer')" [options]="types_conta"
          [label]="'Tipo de recipiente'" (onChange)="setSizesValues($event)"></agenx-select>
        <agenx-select [idInput]="'sizeRecolector'" [control]="getControlOf('sizeContainer')" [options]="sizes_conta"
          [label]="'Tamaño'" (onChange)="setEquivalencyTable()"></agenx-select>
        <agenx-input [control]="getControlOf('quantity')" [type]="'number'" [placeholder]="''" [idInput]="'quantity'"
          [label]="'Cantidad'" (onChange)="setEquivalencyTable()"></agenx-input>
        <agenx-table [tableContent]="equivalency_table" [label]="'Equivalencias'" [labelPosition]="'top'"></agenx-table>
        <div class="agenx-create-edit__button--textarea">
          <agenx-textarea [control]="getControlOf('observations')" class="agenx-create-edit__inputs__textarea"
            [label]="'Observaciones'"></agenx-textarea>
          <div class="agenx-create-edit__button--textarea-buttons">
            @if (gauging_data && editing) {
              <agenx-button class="agenx-create-edit__button" [typeButton]="'red'" [buttonText]="'Cancelar'" (click)="onCancel()"></agenx-button>
            }
            <agenx-button class="agenx-create-edit__button" [buttonText]="btn_add"
              (click)="btn_add === 'Agregar' ? onAddAforo() : onEditCard()"></agenx-button>
          </div>
        </div>
      </div>
    </agenx-card>
    <agenx-card [title]="'LISTA ITEMS AFOROS'" [borderTopLess]="true">
      @if (cards_content.length === 0) {
        <agenx-alert [alertType]="'error'" [alertText]="'No se han agregado aforos.'"></agenx-alert>
      }
      <div class="agenx-create-edit__cards">
        @for (card of cards_page; track card.valuesContent) {
          <agenx-card-sub [typeCard]="card.color ?? 'blue'" [quantity]="card.quantity" [index]="$index"
            (selectedCardSub)="onClickSubCard($event)" [cardSubButtonType]="'twoButton'" [buttonCardText]="'Crear Aforo'"
            [alertCard]="card.alertInfo" [card_info]="card"></agenx-card-sub>
        }
      </div>
      <div class="agenx-mt--4">
        @if (pages.length > 0) {
          <agenx-paginator (returnedPage)="setPaginator($event)" [currentPage]="pageSelected"
            [pages]="pages"></agenx-paginator>
        }
      </div>
    </agenx-card>
    <agenx-card [title]="'EVIDENCIAS'">
      <div class="agenx-create-edit__inputs--signature">

        @if (signatureImg) {
        <div class="agenx-create-edit__signature">
          <img class="agenx-create-edit__signature-img" [src]="signatureImg" alt="signature" srcset="" />
          <a class="agenx-create-edit__signature__edit-file" (click)="editSignature('aforo')">
            <img class="agenx-create-edit__signature__img" alt="edit icon"
              [src]="'../../../../../assets/icons/Icon_Edit.svg'" />
          </a>
          <a class="agenx-create-edit__signature__delete-file" (click)="deleteSignature('aforo')">
            <img class="agenx-create-edit__signature__img" alt="delete icon"
              [src]="'../../../../../assets/icons/Icon_Delete.svg'" />
          </a>
        </div>
        } @else {
          <div>
            <agenx-button [buttonText]="'Firma aforador'" (click)="openDialogSignature('aforo')"
              [sizeButton]="'full'"></agenx-button>
              @if (error_sign_flume) {
                <span class="error-btn">No se han agregado la firma del aforador.</span>
              }
          </div>
        }

        @if (signatureImgSub) {
        <div class="agenx-create-edit__signature">
          <img class="agenx-create-edit__signature-img" [src]="signatureImgSub" alt="signature" srcset="" />
          <a class="agenx-create-edit__signature__edit-file" (click)="editSignature('sub')">
            <img class="agenx-create-edit__signature__img" alt="edit icon"
              [src]="'../../../../../assets/icons/Icon_Edit.svg'" />
          </a>
          <a class="agenx-create-edit__signature__delete-file" (click)="deleteSignature('sub')">
            <img class="agenx-create-edit__signature__img" alt="delete icon"
              [src]="'../../../../../assets/icons/Icon_Delete.svg'" />
          </a>
        </div>
        } @else {
          <div>
            <agenx-button (click)="openDialogSignature('sub')" #buttonTest [buttonText]="'Firma suscriptor'"
              [sizeButton]="'full'"></agenx-button>
              @if (error_sign_sub) {
                <span class="error-btn">No se han agregado la firma del suscriptor.</span>
              }
          </div>
        }
        <agenx-input-img (fileChange)="files = $event" [control]="photos" [type]="'number'" [placeholder]="''"
          [idInput]="'photos'" [label]="'Evidencias fotografícas'" [load_files]="load_evidences"
          (fileDelete)="deletePermanentFile($event)"></agenx-input-img>

      </div>
      @if (!data_form.email) {
        <agenx-input [control]="newEmail" [placeholder]="''" [labelPosition]="'bottom'" [idInput]="'Agregar Correo electrónico'"
          [label]="'Agregar correo electronico'"></agenx-input>
        <span class="error-btn" style="color: black;">Para agregar varios correos debe separarlos por punto y coma (;).</span>
      }
      <div class="agenx-mt--4" style="display: flex; gap: 1rem;">
        <agenx-button class="agenx-create-edit__button" (click)="save()"
          [buttonText]="'Guardar'" [loadingButton]="save_btn"></agenx-button>
        <agenx-button class="agenx-create-edit__button" [typeButton]="'red'"
          [buttonText]="'Volver'" (click)="router.navigateByUrl('/dashboard')" ></agenx-button>
      </div>

    </agenx-card>
  } @else {
    <agenx-alert-radio [alertText]="'Consultando la información del aforo'" alertType="info" [loading]="true"></agenx-alert-radio>
  }
</main>
