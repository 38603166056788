import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AccountService } from './account.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Container } from '../interfaces/create-edit.interface';
import { Signs} from '../interfaces/person.interface';
import { GaugingFull, Totals } from '../interfaces/gauging.interface';

@Injectable({
  providedIn: 'root'
})
export class GaugingService {
  private url = `${environment.api_url}/aforo/app`;
  private gauging_data: GaugingFull | null = null;

  constructor(
    private http: HttpClient,
    private accountSrv : AccountService
  ) {}

  formGauging(data: any, id?: number): Observable<any> {
    let headers = this.accountSrv.headers;
    return !id
    ? this.http.post<any>(`${this.url}/gauging/`, data, {headers})
    : this.http.patch<any>(`${this.url}/gauging/${id}/`, data, {headers});
  }

  deleteFile(id: number): Observable<any> {
    let headers = this.accountSrv.headers;
    return this.http.delete<any>(`${this.url}/gauging/delete_file/${id}/`, {headers});
  }

  downloadGaugingReport(gauging_id: number): Observable<any> {
    let headers = this.accountSrv.headers;
    let data = `?gauging=${gauging_id}`;
    return this.http.get<any>(`${this.url}/gauging/report/${data}`, {headers});
  }

  getContainers(): Observable<Container[]> {
    let headers = this.accountSrv.headers;
    return this.http.get<Container[]>(`${this.url}/containers/`, {headers});
  }

  get gaugingData(): GaugingFull | null {
    return this.gauging_data
  }

  getSigns(niud: string, username: string): Observable<Signs> {
    let headers = this.accountSrv.headers;
    return this.http.get<Signs>(`${this.url}/gauging/signs/?niud=${niud}&username=${username}`,
    {headers});
  }

  getTodayGauging(): Observable<any> {
    let headers = this.accountSrv.headers;
    return this.http.get<any>(`${this.url}/gauging/today/`, { headers });
  }

  getTotals(): Observable<Totals> {
    let headers = this.accountSrv.headers;
    return this.http.get<Totals>(`${this.url}/gauging/totals/`, {headers});
  }

  resendGaugingReport(gauging_id: number): Observable<any> {
    let headers = this.accountSrv.headers;
    let data = `?gauging=${gauging_id}`;
    return this.http.get<any>(`${this.url}/gauging/resend_report/${data}`, {headers});
  }

  set gaugingData(data: GaugingFull | null) {
    this.gauging_data = data;
  }
}
