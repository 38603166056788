import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'agenx-textarea',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
})
export class AgenxTextareaComponent {
  /**
   * Input label
   *
   * @type {string}
   * @memberof AgenxTextareaComponent
   */
  @Input() label: string = 'Input label';

  /**
   * Input idInput
   *
   * @type {string}
   * @memberof AgenxTextareaComponent
   */
  @Input() idInput: string = 'idInput';

  /**
   * Input form control
   *
   * @type {FormControl}
   * @memberof AgenxSelectComponent
   */
  @Input() control: FormControl = new FormControl();


  /**
   * Current classes of the input
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxTextareaComponent
   */
  labelClasses: Record<string, boolean> = {};

  /**
   * Position of the label
   *
   * @type {('bottom' | 'top' )}
   * @memberof AgenxTextareaComponent
   */
  @Input() labelPosition: 'bottom' | 'top' = 'bottom';

  /**
   * Function to set the current classes of the input
   *
   * @memberof AgenxTextareaComponent
   */
  setLabelPosition() {
    this.labelClasses = {
      'agenx-textarea__label--top': this.labelPosition === 'top',
    };
  }

  ngOnInit() {
    this.setLabelPosition();
  }
}
