<div class="agenx-alert-radio">
  <agenx-alert [alertRadio]="true" class="agenx-alert-radio__alert" [alertType]="alertType"
      [alertText]="alertText" [loading]="loading">
    <div class="agenx-alert-radio__content">
      <ng-content></ng-content>
    </div>

  </agenx-alert>
</div>

