import { Routes } from '@angular/router';
import { LoginComponent } from '../UI/pages/login/login.component';
import { DashboardComponent } from '../UI/pages/dashboard/dashboard.component';
import { CreateEditComponent } from '../UI/pages/create-edit/create-edit.component';
import { userLoggedInGuard } from '../guards/user-logged-in.guard';
import { activeUserGuard } from '../guards/active-user.guard';


export const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [userLoggedInGuard] }, 
  { path: 'auth', component: LoginComponent, canActivate: [userLoggedInGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [activeUserGuard] },
  { path: 'create-edit', component: CreateEditComponent, canActivate: [activeUserGuard] },
];
