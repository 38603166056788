<div class="agenx-paginator">
  <ul class="agenx-paginator__dots">
    <li [ngClass]="{'agenx-paginator__arrow--disabled': currentPage === 1}" (click)="clickOnArrow('left')"
      class="agenx-paginator__arrow">
      <img class="agenx-paginator__arrow-img agenx-paginator__arrow-img--left"
        src="../../../../../assets/icons/Icon_Arrow.svg" alt="Alert Icon">
    </li>
    @for (page of pages; track page) {
    <li (click)="changePage(page)" class="agenx-paginator__dot"
      [ngClass]="{'agenx-paginator__dot--active': page === currentPage}">
    </li>
    } @empty {
    <li class="agenx-paginator__dot"></li>
    }
    <li [ngClass]="{'agenx-paginator__arrow--disabled': currentPage === pages.length}" (click)="clickOnArrow('right')"
      class="agenx-paginator__arrow">
      <img class="agenx-paginator__arrow-img" src="../../../../../assets/icons/Icon_Arrow.svg" alt="Alert Icon">
    </li>
  </ul>
</div>