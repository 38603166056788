import { NgClass } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'agenx-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class AgenxButtonComponent implements OnInit {

  /**
   * Text to be displayed in the button
   *
   * @type {string}
   * @memberof AgenxButtonComponent
   */
  @Input() buttonText: string = '';

  /**
   * Type of the button color pallete
   *
   * @type {('green' | 'blue' | 'red' | 'lightblue')}
   * @memberof AgenxButtonComponent
   */
  @Input() typeButton: 'green' | 'blue' | 'red' | 'lightblue' = 'blue';

  /**
   * Type of the button size
   *
   * @type {('large' | 'full' | 'normal')}
   * @memberof AgenxButtonComponent
   */
  @Input() sizeButton: 'large' | 'full' | 'normal' = 'normal';

  /**
   * Set if button is loading or not
   *
   * @type {('loading' | 'normal')}
   * @memberof AgenxButtonComponent
   */
  @Input() loadingButton: 'loading' | 'normal' = 'normal';


  /**
   * Current classes of the button based on the typeButton, sizeButton and loadingButton
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxButtonComponent
   */
  currentClasses: Record<string, boolean> = {};

  /**
   * If the button is disabled or not
   *
   * @type {boolean}
   * @memberof AgenxButtonComponent
   */
  @Input() disabled: boolean = false;

  constructor() { }

  /**
   * Function returns the class of the button based on the loadingButton
   *
   * @return {*}  {boolean}
   * @memberof AgenxButtonComponent
   */
  setLoadingButton(): boolean {
    return this.loadingButton === 'loading';
  }

  /**
   * Function to set the current classes of the button
   *
   * @memberof AgenxButtonComponent
   */
  setCurrentClasses() {
    this.currentClasses = {
      'agenx-button--green': this.typeButton === 'green',
      'agenx-button--blue': this.typeButton === 'blue',
      'agenx-button--lightblue': this.typeButton === 'lightblue',
      'agenx-button--red': this.typeButton === 'red',
      'agenx-button--large': this.sizeButton === 'large',
      'agenx-button--full': this.sizeButton === 'full',
      'agenx-button--loading': this.setLoadingButton(),
    };
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change['loadingButton']) {
      this.setCurrentClasses()
    }
  }

  ngOnInit() {
    this.setCurrentClasses();
  }

}
