<div class="agenx-table">
  <div class="agenx-table__container">
    <table class="agenx-table__table">
      <tbody class="agenx-table__tbody">
        @for (tr of tableContent; track tr) {
        <tr class="agenx-table__tr">
          @for (td of tr.td; track td) {
          <td class="agenx-table__td"> {{ td }}</td>
          }
        </tr>
        }
      </tbody>
    </table>
    <label [for]="idInput" class="agenx-table__label" [ngClass]="labelClasses"> {{ label }} </label>
  </div>
</div>