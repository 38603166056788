<div class="agenx-tab">
  <div class="agenx-tab__tabs">
    @for (tab of tabs; track tab.name) {
    <button class="agenx-tab__tab" [ngClass]="{'agenx-tab__tab--active': tab.active}" (click)="selectTab(tab)"> {{
      tab.name }} </button>
    } @empty {
    <button class="agenx-tab__tab agenx-tab__tab--active">Default</button>
    }
  </div>
</div>
