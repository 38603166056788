import { Component } from '@angular/core';
import { AgenxButtonComponent } from '../../atoms/button/button.component';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { AccountService } from '../../../../services/account.service';

@Component({
  selector: 'agenx-header',
  standalone: true,
  imports: [AgenxButtonComponent, RouterOutlet, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class AgenxHeaderComponent {
  constructor(
    private router: Router,
    private accountSrv: AccountService
  ){}

  logout(): void {
    this.accountSrv.logout();
    this.router.navigateByUrl('');
  }
}
