import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Option } from './select.interfaces';

@Component({
  selector: 'agenx-select',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class AgenxSelectComponent {
  /**
   * Input placeholder
   *
   * @type {string}
   * @memberof AgenxSelectComponent
   */
  @Input() placeholder: string = 'Input placeholder';

  /**
   * Input label
   *
   * @type {string}
   * @memberof AgenxSelectComponent
   */
  @Input() label: string = 'Input label';

  /**
   * Input form control
   *
   * @type {FormControl}
   * @memberof AgenxSelectComponent
   */
  @Input() control: FormControl = new FormControl();

  /**
   * Input idInput
   *
   * @type {string}
   * @memberof AgenxSelectComponent
   */
  @Input() idInput: string = 'idInput';

  /**
   * Current classes of the input
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxSelectComponent
   */
  labelClasses: Record<string, boolean> = {};

  /**
   * Position of the label
   *
   * @type {('bottom' | 'top' )}
   * @memberof AgenxSelectComponent
   */
  @Input() labelPosition: 'bottom' | 'top' = 'bottom';

  @Input() options: Option[] = [];

  @Output() onChange = new EventEmitter<any>();

  /**
   * Function to set the current classes of the input
   *
   * @memberof AgenxSelectComponent
   */
  setLabelPosition() {
    this.labelClasses = {
      'agenx-select__label--top': this.labelPosition === 'top',
    };
  }

  ngOnInit() {
    this.setLabelPosition();
  }

  returnChange(event: Event) {
    this.onChange.emit(event.target);
  }
}
