<div class="agenx-alert" [ngClass]="currentClasses">
  @if (!loading) {
    <img class="agenx-alert__logo" [src]="urlIcon" alt="Alert Icon">
    <p class="agenx-alert__text"> {{ alertText }} </p>
  } @else {
    <picture class="agenx-alert__loading">
      <img class="agenx-alert__loading-icon" src="../../../../../assets/icons/Icon_Loading_Blue.svg" alt="Loading Icon">
    </picture>
    <p class="agenx-alert__text no-background"> {{ alertText }} </p>
  }
  <div class="agenx-alert__content">
    <ng-content></ng-content>
  </div>
</div>
