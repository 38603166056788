import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AgenxButtonComponent } from '../../atoms/button/button.component';
import { AgenxInputComponent } from '../../atoms/input/input.component';
import { ImgAuth, User } from './auth.interfaces';
import { RouterModule } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AgenxAlertComponent } from "../../atoms/alert/alert.component";
import { AgenxAlertRadioComponent } from "../../moleculs/alert-radio/alert-radio.component";


@Component({
    selector: 'agenx-auth',
    standalone: true,
    templateUrl: './auth.component.html',
    styleUrl: './auth.component.scss',
    imports: [AgenxButtonComponent, AgenxInputComponent, RouterModule, ReactiveFormsModule, AgenxAlertComponent, AgenxAlertRadioComponent]
})
export class AgenxAuthComponent {

  @ViewChild('buttonLogin') buttonLogin: AgenxButtonComponent =
    new AgenxButtonComponent();
  /**
   * Image to be displayed in the auth component
   *
   * @type {ImgAuth}
   * @memberof AgenxAuthComponent
   */
  @Input() imgAuth: ImgAuth = { imgURL: '/assets/img/Logo_Aforos_genXapp.png', imgAlt: 'GenXapp Logo' };

  /**
   * User form control
   *
   * @type {FormControl}
   * @memberof AgenxAuthComponent
   */
  @Input() user: FormControl = new FormControl('');

  /**
   * Password form control
   *
   * @type {FormControl}
   * @memberof AgenxAuthComponent
   */
  @Input() password: FormControl = new FormControl('');
  /**
   * Password form control
   *
   * @type {FormControl}
   * @memberof AgenxAuthComponent
   */
  @Input() error: string | null = null;

  @Input() state_button: "loading" | "normal" = "normal";
  /**
   * Event emitter to return the user and password values
  *
  * @memberof AgenxAuthComponent
  */
  @Output() login = new EventEmitter<User>();

  /**
   * Emits the user and password values
   *
   * @memberof AgenxAuthComponent
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['state_button']) {
      this.buttonLogin.loadingButton = this.state_button;
      this.buttonLogin.setCurrentClasses();
    }
  }

  returnAuthValues() {
    this.login.emit({ user: this.user.value, password: this.password.value });
  }




}
