import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '../services/account.service';

export const userLoggedInGuard: CanActivateFn = (route, state) => {
  const accountSvc = inject(AccountService);
  const router = inject(Router);

  if (!accountSvc.userValue) {
    return true;
  }

  const url = router.createUrlTree(['/dashboard']);
  return url
};
