import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'agenx-table',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class AgenxTableComponent {

  /**
   * Input label
   *
   * @type {string}
   * @memberof AgenxTableComponent
   */
  @Input() label: string = 'Input label';

  /**
   * Input idInput
   *
   * @type {string}
   * @memberof AgenxTableComponent
   */
  @Input() idInput: string = 'idInput';

  /**
   * Table content
   *
   * @type {{ td: any[] }[]}
   * @memberof AgenxTableComponent
   */
  @Input() tableContent: { td: any[] }[] = [];

  /**
 * Input form control
 *
 * @type {FormControl}
 * @memberof AgenxTableComponent
 */
  @Input() control: FormControl = new FormControl();

  /**
 * Current classes of the input
 *
 * @type {Record<string, boolean>}
 * @memberof AgenxTableComponent
 */
  labelClasses: Record<string, boolean> = {};

  /**
 * Position of the label
 *
 * @type {('bottom' | 'top' )}
 * @memberof AgenxTableComponent
 */
  @Input() labelPosition: 'bottom' | 'top' = 'bottom';

  /**
 * Function to set the current classes of the input
 *
 * @memberof AgenxTableComponent
 */
  setLabelPosition() {
    this.labelClasses = {
      'agenx-table__label--top': this.labelPosition === 'top'
    };
  }

  ngOnInit() {
    this.setLabelPosition();
  }

}
