<agenx-header></agenx-header>
<main class="agenx-dashboard">
  @if (show_totals && micros_values.length > 0) {
    <agenx-card [title]="'INFORMACIÓN GENERAL'">
      <div class="agenx-dashboard__inputs--four">
        <agenx-input [placeholder]="''" [labelPosition]="'top'" [idInput]="'Nombre'"
          [label]="'Nombre'" [value]="user?.name!" [readonly]="true"></agenx-input>
        <agenx-input [placeholder]="''" [labelPosition]="'top'" [idInput]="'Nombre usuario'"
          [label]="'Nombre usuario'" [value]="user?.username!" [readonly]="true"></agenx-input>
        <agenx-input [placeholder]="''" [labelPosition]="'top'" [idInput]="user?.identification!"
          [label]="'Identicación'" [value]="user?.identification!" [readonly]="true"></agenx-input>
        <agenx-input [placeholder]="''" [labelPosition]="'top'" [idInput]="'Fecha'"
          [label]="'Fecha'" [value]="date_today.toLocaleDateString()" [readonly]="true"></agenx-input>
      </div>
    </agenx-card>

    <agenx-card [title]="'TOTALES'">
      <agenx-totals [totalGaugedSub]="gauged_sub"
        [totalMonthSub]="month_subs"
        [totalDaySub]="today_sub"
        [totalGauged]="totals_gauged"
        [totalMonth]="totals_month"
        [totalDay]="totals_today"
        [ordynary]="totals_ordinary"
        [extraOrdynary]="totals_extra"
        [permanent]="totals_permanent"
        [todayDate]="today_sub"></agenx-totals>
    </agenx-card>

    <agenx-tab (selectedTab)="setTabs($event)" [tabs]="tabs">
    </agenx-tab>
    @switch (tabSelected) {
      @case (tabs[0].name) {
        <agenx-card [title]="micro_name" [borderTopLess]="true" (buttonClick)="restartMicroTab()"
            [button]="btn_restart">
          @if (loading_not_gau) {
            <agenx-alert-radio [alertText]="'Consultando información de suscriptores no aforados.'"
              alertType="info" [loading]="true"></agenx-alert-radio>
          }

          @if (micros_select.value === '---') {
            <agenx-select [idInput]="'micros_select'" [control]="micros_select" [options]="micros_values"
            [label]="'Microrrutas'" (onChange)="setMicroroutes($event)"></agenx-select>
          }
          @else {
            @if (this.not_gauging_cards.length === 0 && !loading_not_gau) {
              <agenx-alert class="agenx-mb--3" [alertType]="'error'"
                [alertText]="'No hay suscriptores para aforar.'"></agenx-alert>
            }
            <div class="agenx-dashboard__cards">
              @for (subs_card of cards_to_show; track subs_card.valuesContent) {
                <agenx-card-sub [buttonCardText]="!subs_card.alertInfo ? 'Crear Aforo' : ''"
                  [alertCard]="subs_card.alertInfo" [card_info]="subs_card" (selectedCardSub)="goToCreateEdit($event)">
                </agenx-card-sub>
              }
            </div>
            }
          <div class="agenx-mt--4">
            @if (not_gauging_pages.length > 0) {
              <agenx-paginator (returnedPage)="setPaginator($event)" [currentPage]="pageSelected[1]"
                [pages]="not_gauging_pages"></agenx-paginator>
            }
          </div>
        </agenx-card>
      }
      @case (tabs[1].name) {
        <agenx-card [title]="tabs[1].name" [borderTopLess]="true">
          <div class="agenx-dashboard__search">
            <h3 class="agenx-title">Niud, número de contrato, identicación o nombre</h3>
            <div class="agenx-dashboard__search--inputs">
              <agenx-input [placeholder]="'Suscriptor'" [labelPosition]="'top'" [control]="search_input"
                [label]="''" (onEnterPress)="searchSubscribers()"></agenx-input>
              <agenx-button #buttonTest (click)="searchSubscribers()" [buttonText]="'Buscar'" [sizeButton]="'large'"
                [loadingButton]="loading"></agenx-button>
            </div>
            @if (this.subs_search.length === 0) {
              <agenx-alert [alertType]="'error'"
                [alertText]="'No se encuentran suscriptores.'"></agenx-alert>
            }
          </div>
          <div class="agenx-dashboard__cards">
            @for (subs_card of cards_to_show; track subs_card.valuesContent) {
              <agenx-card-sub [buttonCardText]="'Crear Aforo'" [cardSubButtonType]="subs_card.gauging! ? 'threeButton' : 'oneButton'"
                [alertCard]="subs_card.alertInfo" [card_info]="subs_card" (selectedCardSub)="goToCreateEdit($event)">
              </agenx-card-sub>
            }
          </div>
          <div class="agenx-mt--4">
            @if (pages.length > 0) {
              <agenx-paginator (returnedPage)="setPaginator($event)" [currentPage]="pageSelected[0]"
                [pages]="pages"></agenx-paginator>
            }
          </div>
        </agenx-card>
      }
      @case (tabs[2].name) {
        <agenx-card [title]="tabs[2].name" [borderTopLess]="true">
          @if (loading_gau) {
            <agenx-alert-radio [alertText]="'Consultando subcriptores aforados hoy.'"
              alertType="info" [loading]="true"></agenx-alert-radio>
          }
          <div class="agenx-dashboard__cards">
            @for (subs_card of cards_to_show; track subs_card.valuesContent) {
            <agenx-card-sub (selectedCardSub)="onClickSubCard($event)" [cardSubButtonType]="'threeButton'"
              [buttonCardText]="'Crear Aforo'" [alertCard]="subs_card.alertInfo"
              [card_info]="subs_card"></agenx-card-sub>
            }
          </div>
          @if (gauging_cards.length === 0 && !loading_gau) {
            <agenx-alert class="agenx-mb--3" [alertType]="'error'"
              [alertText]="'No hay suscriptores aforados hoy.'"></agenx-alert>
          }
          <div class="agenx-mt--4">
            @if (gauging_pages.length > 0) {
              <agenx-paginator (returnedPage)="setPaginator($event)" [currentPage]="pageSelected[2]"
                [pages]="gauging_pages"></agenx-paginator>
            }
          </div>
        </agenx-card>
      }
    }
  } @else {
    <agenx-alert-radio [alertText]="'Consultando la información del periodo'" alertType="info" [loading]="true"></agenx-alert-radio>
  }
</main>

