<div class="agenx-card" [ngClass]="currentClasses">
  <header class="agenx-card__header">
    <h4 class="agenx-card__title"> {{ title }} </h4>
    @if (button) {
      <agenx-circle-button (click)="onButtonClick()"
        [buttonCircleSrc]="'../../../../../assets/icons/Icon_comeback2.svg'"
        [buttonCircleTitle]="button" style="margin-left: 1rem;"></agenx-circle-button>
    }
    <span class="agenx-card__line"></span>
  </header>
  <div class="agenx-card__container">
    <ng-content></ng-content>
  </div>
</div>
