import { Component, Input } from '@angular/core';
import { AgenxAlertComponent } from '../../atoms/alert/alert.component';
import { AgenxRadioComponent } from '../../atoms/radio/radio.component';

@Component({
  selector: 'agenx-alert-radio',
  standalone: true,
  imports: [AgenxAlertComponent, AgenxRadioComponent],
  templateUrl: './alert-radio.component.html',
  styleUrl: './alert-radio.component.scss'
})
export class AgenxAlertRadioComponent {

  /**
   * Alert text
   *
   * @type {string}
   * @memberof AlertRadioComponent
   */
  @Input() alertText: string = 'Alert';
  /**
   * Alert text
   *
   * @type {string}
   * @memberof AlertRadioComponent
   */
  @Input() loading: boolean = false;

  /**
   * Alert type
   *
   * @type {('error' | 'warning' | 'info')}
   * @memberof AlertRadioComponent
   */
  @Input() alertType: 'error' | 'warning' | 'info' = 'info';

}
