import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgenxCardComponent } from '../../atoms/card/card.component';
import { AgenxCircleButtonComponent } from '../../atoms/circle-button/circle-button.component';

@Component({
  selector: 'agenx-card-create',
  standalone: true,
  imports: [AgenxCardComponent, AgenxCircleButtonComponent],
  templateUrl: './card-create.component.html',
  styleUrl: './card-create.component.scss'
})
export class AgenxCardCreateComponent {

  /**
   * Card title inside
   *
   * @type {string}
   * @memberof AgenxCardCreateComponent
   */
  @Input() titleForCardInside: string = 'Card title';

  /**
   * Card title
   *
   * @type {string}
   * @memberof AgenxCardCreateComponent
   */
  @Input() title: string = 'Card title';

  /**
   * Card edit
   *
   * @type {string}
   * @memberof AgenxCardCreateComponent
   */
  @Output() edit = new EventEmitter<string>();

  /**
   * Function to emit the selected card content
   *
   * @param {string} action
   * @memberof AgenxCardCreateComponent
   */
  clickEdit(action: string) {
    this.edit.emit(action);
  }

}
