import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'agenx-input',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class AgenxInputComponent implements OnInit {
  // TODO: completar el reuso de este componente

  /**
   * Input placeholder
   *
   * @type {string}
   * @memberof AgenxInputComponent
   */
  @Input() placeholder: string = 'Input placeholder';

  /**
   * Input label
   *
   * @type {string}
   * @memberof AgenxInputComponent
   */
  @Input() label: string = 'Input label';

  /**
   * Input idInput
   *
   * @type {string}
   * @memberof AgenxInputComponent
   */
  @Input() idInput: string = 'idInput';

  /**
   * Input value
   *
   * @type {string}
   * @memberof AgenxInputComponent
   */
  @Input() value: string = '';
  /**
   * Input readonly
   *
   * @type {boolean}
   * @memberof AgenxInputComponent
   */
  @Input() readonly: boolean = false;

  /**
   * Input type
   *
   * @type {('text' | 'password' | 'number')}
   * @memberof AgenxInputComponent
   */
  @Input() type: 'text' | 'password' | 'number' = 'text';

  /**
   * Current classes of the input
   *
   * @type {Record<string, boolean>}
   * @memberof AgenxInputComponent
   */
  labelClasses: Record<string, boolean> = {};

  /**
   * Input form control
   *
   * @type {FormControl}
   * @memberof AgenxSelectComponent
   * @memberof AgenxSelectComponent
   */
  @Input() control: FormControl = new FormControl();

  /**
   * Position of the label
   *
   * @type {('bottom' | 'top' )}
   * @memberof AgenxInputComponent
   */
  @Input() labelPosition: 'bottom' | 'top' = 'bottom';

  @Output() onChange = new EventEmitter<any>();
  @Output() onEnterPress = new EventEmitter<any>();

  /**
   * Function to set the current classes of the input
   *
   * @memberof AgenxInputComponent
   */
  setLabelPosition() {
    this.labelClasses = {
      'agenx-input__label--top': this.labelPosition === 'top',
    };
  }

  ngOnInit() {
    this.setLabelPosition();
    this.value && this.control.setValue(this.value);
  }

  returnEnterPress(event: Event) {
    this.onEnterPress.emit(event.target);
  }

  returnChange(event: Event) {
    this.onChange.emit(event.target);
  }
}
