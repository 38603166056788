import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from './tab.interfaces';

@Component({
  selector: 'agenx-tab',
  standalone: true,
  imports: [NgClass],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss'
})
export class AgenxTabComponent {

  /**
   * Tabs to display
   *
   * @type {Tab[]}
   * @memberof AgenxTabComponent
   */
  @Input() tabs: Tab[] = [];

  /**
   * Event emitter to emit the selected tab
   *
   * @memberof AgenxTabComponent
   */
  @Output() selectedTab = new EventEmitter<Tab>()

  selectTab(tabClicked: Tab) {
    this.tabs.map(tab => {
      tab.active = tab.name === tabClicked.name;
    })
    this.selectedTab.emit(tabClicked);
  }

}
