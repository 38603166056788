import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertInfo, CardValueContent } from './card-sub.interface';
import { AgenxButtonComponent } from '../../atoms/button/button.component';
import { AgenxAlertComponent } from '../../atoms/alert/alert.component';
import { AgenxCircleButtonComponent } from '../../atoms/circle-button/circle-button.component';
import { CardContent } from '../../../../interfaces/gauging.interface';
import { LimitCharsPipe } from "../../../../pipes/limit-chars.pipe";

@Component({
    selector: 'agenx-card-sub',
    standalone: true,
    templateUrl: './card-sub.component.html',
    styleUrl: './card-sub.component.scss',
    imports: [
        NgClass,
        AgenxButtonComponent,
        AgenxCircleButtonComponent,
        AgenxAlertComponent,
        LimitCharsPipe
    ]
})
export class AgenxCardSubComponent {
  /**
   * Card button text
   *
   * @type {string}
   * @memberof AgenxCardSubComponent
   */
  @Input() buttonCardText: string = '';

  /**
   * Card sub type to display
   *
   * @type {'oneButton' | 'threeButton' | 'twoButton'}
   * @memberof AgenxCardSubComponent
   */
  @Input() cardSubButtonType: 'oneButton' | 'threeButton' | 'twoButton' =
    'oneButton';

  /**
   * Type of the card (orange or blue)
   *
   * @type {('orange' | 'blue')}
   * @memberof AgenxCardSubComponent
   */
  @Input() typeCard: 'orange' | 'blue' = 'blue';

  /**
   * Alert to display in the card
   *
   * @type {AlertInfo}
   * @memberof AgenxCardSubComponent
   */
  @Input() alertCard: AlertInfo | undefined = {
    alertText: '',
    alertType: 'info',
  };

  /**
   * Quantity to display in the card
   *
   * @type {number}
   * @memberof AgenxCardSubComponent
   */
  @Input() quantity: number | undefined = 0;

  /**
   * info of the card
   *
   * @type {CardContent}
   * @memberof AgenxAuthComponent
   */
  @Input() card_info!: CardContent;
  /**
   * info of the card
   *
   * @type {number}
   * @memberof AgenxAuthComponent
   */
  @Input() index!: number;

  /**
   * Event emitter to emit the selected card content
   *
   * @memberof AgenxCardSubComponent
   */
  @Output() selectedCardSub = new EventEmitter<{
    quantity?: number;
    cardSubButtonType: string;
    index: number;
    card_info: CardContent;
  }>();

  /**
   * Function to emit the selected card content
   *
   * @memberof AgenxCardSubComponent
   */
  clickOnCardsub(buttonName: string) {
    this.selectedCardSub.emit({
      quantity: this.quantity,
      cardSubButtonType: buttonName,
      index: this.index,
      card_info: this.card_info
    });
  }
}
