import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'agenx-circle-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './circle-button.component.html',
  styleUrl: './circle-button.component.scss'
})
export class AgenxCircleButtonComponent {

  /**
   * Circle button title
   *
   * @type {string}
   * @memberof AgenxCircleButtonComponent
   */
  @Input() buttonCircleTitle: string = 'button';

  /**
   * Circle button source for the icon
   *
   * @type {string}
   * @memberof AgenxCircleButtonComponent
   */
  @Input() buttonCircleSrc: string = '';

  /**
 * Type of the button color pallete
 *
 * @type {('red' | 'blue' )}
 * @memberof AgenxCircleButtonComponent
 */
  @Input() typeButton: 'red' | 'blue' = 'blue';

  /**
 * Current classes of the button based on the typeButton, sizeButton and loadingButton
 *
 * @type {Record<string, boolean>}
 * @memberof AgenxCircleButtonComponent
 */
  currentClasses: Record<string, boolean> = {};

  /**
 * Function to set the current classes of the button
 *
 * @memberof AgenxCircleButtonComponent
 */
  setCurrentClasses() {
    this.currentClasses = {
      'agenx-circle-button--red': this.typeButton === 'red',
    };
  }

  ngOnInit() {
    this.setCurrentClasses();
  }
}
